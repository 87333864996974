import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { DomainSearchForm } from '@components/domain-search-form/DomainSearchForm';
import { DomainSearchAvailabilityDisplay } from '@components/domain-search-availability/DomainSearchAvailabilityDisplay';
import { DomainSearchResultsDrawer } from '@components/domain-search-results/DomainSearchResultsDrawer';
import { useDomainSearchStateStore } from '@stores/DomainSearchStateStore';
import { useShallow } from 'zustand/react/shallow';
import { config } from '@config/constants';
import { getDomainPriceInfo } from '@fetch/getDomainPriceInfo';
import { usePageConfigStore } from '@stores/PageConfigStore';
import { convertToASCII } from './util/convertToASCII';
import { getIsInputValidOnSubmit } from './util/getIsInputValidOnSubmit';
import { getIsRequestedInputChangeValid } from './util/getIsRequestedInputChangeValid';
import { updateSearchQueryParam } from './util/updateSearchQueryParam';

const App: FC = () => {
  const [isErrorState, setIsErrorState] = useState(false);

  const { pageConfig, isRedirectComponent, redirectUrl, enableIntercap, sortBy } = usePageConfigStore(
    useShallow(state => ({
      pageConfig: state.pageConfig,
      isRedirectComponent: state.isRedirectComponent,
      redirectUrl: state.redirectUrl,
      enableIntercap: state.enableIntercap,
      sortBy: state.sortBy,
    })),
  );

  const { searchResult, inputValue, isSearching, isFirstSearchCompleted, setIsFirstSearchCompleted } =
    useDomainSearchStateStore(
      useShallow(state => ({
        searchResult: state.searchResult,
        inputValue: state.inputValue,
        isSearching: state.isSearching,
        isFirstSearchCompleted: state.isFirstSearchCompleted,
        setIsFirstSearchCompleted: state.setIsFirstSearchCompleted,
      })),
    );

  const { setSearchResult, setInputValue, setIsSearching } = useDomainSearchStateStore();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchResult(null);

    if (!inputValue) {
      setIsErrorState(true);
      return;
    }

    const encodedInputValue = convertToASCII(inputValue);

    if (!getIsInputValidOnSubmit(`${encodedInputValue}.${config.registryTLD}`)) {
      setIsErrorState(true);
      return;
    }

    setIsErrorState(false);
    setInputValue(encodedInputValue);

    setIsSearching(true);
    await getDomainPriceInfo(`${encodedInputValue}.${config.registryTLD}`, pageConfig?.query_registrars || [], sortBy);
    updateSearchQueryParam(encodedInputValue);
    setIsSearching(false);
    setIsFirstSearchCompleted(true);
  };

  const redirectHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRedirecting(true);

    if (!redirectUrl) {
      console.error('Redirect URL not found');
      setIsRedirecting(false);
      return;
    }

    if (!inputValue) {
      setIsErrorState(true);
      setIsRedirecting(false);
      return;
    }

    const encodedInputValue = convertToASCII(inputValue);

    if (!getIsInputValidOnSubmit(`${encodedInputValue}.${config.registryTLD}`)) {
      setIsErrorState(true);
      setIsRedirecting(false);
      return;
    }

    setIsErrorState(false);
    setInputValue(encodedInputValue);
    setTimeout(() => {
      window.location.assign(`${redirectUrl}?search=${encodedInputValue}`);
    }, 500); // Delay needed to send event data during redirect
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const requestedInput = e.target.value;
    if (!getIsRequestedInputChangeValid(requestedInput)) {
      return;
    }

    setInputValue(requestedInput.toLowerCase());
  };

  return (
    <>
      {isRedirectComponent ? (
        // REDIRECT COMPONENT
        <DomainSearchForm
          inputValue={inputValue}
          isErrorState={isErrorState}
          onChangeHandler={onChangeHandler}
          isSearchDisabled={isRedirecting}
          submitHandler={redirectHandler}
        />
      ) : (
        // SEARCH COMPONENT
        <>
          {!isFirstSearchCompleted && (
            <DomainSearchForm
              inputValue={inputValue}
              isErrorState={isErrorState}
              onChangeHandler={onChangeHandler}
              submitHandler={onSubmitHandler}
              isSearchDisabled={isSearching}
            />
          )}

          {searchResult && (
            <>
              <DomainSearchAvailabilityDisplay
                domain={searchResult.domain.domain}
                isDomainAvailable={searchResult.domain.available}
                isDomainPremium={searchResult.domain.is_premium}
              />
              {searchResult.domain.available && (
                <div className={'mb-14 md:mb-[88px]'}>
                  <DomainSearchResultsDrawer enableIntercapRegistrar={enableIntercap} />
                </div>
              )}
            </>
          )}

          {isFirstSearchCompleted && (
            <>
              {searchResult && searchResult.domain.available && (
                <div className={'domain-search-heading-2 text-center mb-6 md:mb-12'}>Search for Other Domains</div>
              )}
              <DomainSearchForm
                inputValue={inputValue}
                isErrorState={isErrorState}
                onChangeHandler={onChangeHandler}
                submitHandler={onSubmitHandler}
                isSearchDisabled={isSearching}
                isSearchAgainType={true}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default App;
