import { FC } from 'react';

export const SearchIcon: FC = () => {
  return (
    <svg viewBox={'0 0 32 32'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M14.6666 5.33332C9.51197 5.33332 5.33329 9.512 5.33329 14.6667C5.33329 19.8213 9.51197 24 14.6666 24C17.1812 24 19.4636 23.0056 21.1418 21.3885C21.1769 21.3429 21.2153 21.299 21.2572 21.2572C21.299 21.2154 21.3428 21.1769 21.3885 21.1418C23.0055 19.4636 24 17.1813 24 14.6667C24 9.512 19.8213 5.33332 14.6666 5.33332ZM24.0425 22.1569C25.6847 20.104 26.6666 17.5 26.6666 14.6667C26.6666 8.03924 21.294 2.66666 14.6666 2.66666C8.03921 2.66666 2.66663 8.03924 2.66663 14.6667C2.66663 21.2941 8.03921 26.6667 14.6666 26.6667C17.5 26.6667 20.104 25.6847 22.1569 24.0425L27.0571 28.9428C27.5779 29.4635 28.4221 29.4635 28.9428 28.9428C29.4635 28.4221 29.4635 27.5779 28.9428 27.0572L24.0425 22.1569Z'
        }
        fill={'currentColor'}
      />
    </svg>
  );
};
