export const config = {
  registryTLD: (import.meta.env.VITE_ICP_REGISTRY_TLD as string) || 'inc',
  registryAPIBaseUrl: (import.meta.env.VITE_ICP_EXTERNAL_API_URL as string) || null,
  domainSuggestions: {
    useFeature: (import.meta.env.VITE_ICP_FEATURE_DOMAIN_SUGGESTIONS as string) === 'true',
  },
};

export const INTERCAP_REGISTRAR = 'intercap';

// Webflow elements
export const WEBFLOW_PREMIUM_CONTACT_FORM_MODAL = '[data-modal="premium-contact-form"]';
export const WEBFLOW_CURRENT_DOMAIN_TEXT_SPAN = '[data-text-span="current-domain-name"]';
