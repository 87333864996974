import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { usePageConfigStore } from '@stores/PageConfigStore.ts';
import { getPageConfig } from '@fetch/getPageConfig.ts';
import { startupValidation } from '@config/startup.ts';
import './index.scss';

const rootSelector = '[domain-search-root]';

startupValidation(rootSelector);

usePageConfigStore.getState().setPageConfig(getPageConfig(rootSelector));

if (usePageConfigStore.getState().pageConfig === null) {
  console.error('Missing page config');
}

ReactDOM.createRoot(document.querySelector(rootSelector)!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
