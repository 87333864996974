import { FC } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  INTERCAP_REGISTRAR,
  WEBFLOW_CURRENT_DOMAIN_TEXT_SPAN,
  WEBFLOW_PREMIUM_CONTACT_FORM_MODAL,
} from '@config/constants';
import { useDomainSearchStateStore } from '@stores/DomainSearchStateStore';
import { usePageConfigStore } from '@stores/PageConfigStore';
import { DomainSearchRegistrarCard } from './components/DomainSearchRegistrarCard';

import './DomainSearchResultsDrawer.styles.scss';

export interface DomainSearchResultsDrawerProps {
  enableIntercapRegistrar?: boolean;
}

export const DomainSearchResultsDrawer: FC<DomainSearchResultsDrawerProps> = ({ enableIntercapRegistrar = false }) => {
  const { intercapRegistrarInfo, partnerRegistrarInfos } = usePageConfigStore(
    useShallow(state => ({
      intercapRegistrarInfo: state.intercapRegistrarInfo,
      partnerRegistrarInfos: state.partnerRegistrarInfos,
    })),
  );

  const { searchResult, intercapPriceInfo, partnerPriceInfos } = useDomainSearchStateStore(
    useShallow(state => ({
      searchResult: state.searchResult,
      intercapPriceInfo: state.intercapPriceInfo,
      partnerPriceInfos: state.partnerPriceInfos,
    })),
  );

  return (
    <div className="domain-search-results-wrapper">
      {enableIntercapRegistrar &&
        searchResult &&
        searchResult.domain.available &&
        intercapRegistrarInfo &&
        intercapPriceInfo && (
          <>
            <DomainSearchRegistrarCard
              cardType={'intercap'}
              ctaText={
                intercapPriceInfo.error === 'PREMIUM_CONTACT_SUPPORT' || searchResult.domain.is_premium
                  ? 'Make a Deal'
                  : 'Buy Now'
              }
              externalLink={
                intercapPriceInfo.error === 'PREMIUM_CONTACT_SUPPORT' || searchResult.domain.is_premium
                  ? '#'
                  : intercapRegistrarInfo.redirect_url({ domain: searchResult.domain }) || '#missing'
              }
              logoUrl={intercapRegistrarInfo.logo_url || 'missing'}
              price={`$${intercapPriceInfo.registrar_price}`}
              registrarName={INTERCAP_REGISTRAR}
              hidePrice={intercapPriceInfo.error === 'PREMIUM_CONTACT_SUPPORT' || searchResult.domain.is_premium}
              anchorTarget={
                intercapPriceInfo.error === 'PREMIUM_CONTACT_SUPPORT' || searchResult.domain.is_premium
                  ? '_self'
                  : '_blank'
              }
              onClick={
                intercapPriceInfo.error === 'PREMIUM_CONTACT_SUPPORT' || searchResult.domain.is_premium
                  ? () => {
                      const premiumContactModal = document.querySelector(WEBFLOW_PREMIUM_CONTACT_FORM_MODAL);
                      if (!premiumContactModal) {
                        console.error('Premium contact form modal not found');
                        return;
                      }

                      document.querySelectorAll(WEBFLOW_CURRENT_DOMAIN_TEXT_SPAN).forEach(span => {
                        span.textContent = searchResult.domain.domain; //TODO: enable internationalization support (don't show punycode)
                      });

                      premiumContactModal.classList.add('is-active');
                    }
                  : undefined
              }
            />
            <div className="domain-search-partners-divider">
              <p>Additional ways to purchase</p>
            </div>
          </>
        )}
      <div className="domain-search-results-partners-group">
        {searchResult &&
          searchResult.domain.available &&
          partnerPriceInfos.map(priceInfo => {
            const domainInfo = searchResult.domain;
            const registrarInfo = partnerRegistrarInfos.find(r => r.registrar_name === priceInfo.registrar_name);
            const externalLink = registrarInfo?.redirect_url({ domain: domainInfo.domain }) || '#missing';
            const logoUrl = registrarInfo?.logo_url || 'missing';
            return (
              <DomainSearchRegistrarCard
                key={priceInfo.registrar_name}
                externalLink={externalLink}
                logoUrl={logoUrl}
                price={`$${priceInfo.registrar_price}`}
                registrarName={priceInfo.registrar_name}
              />
            );
          })}
      </div>
    </div>
  );
};
