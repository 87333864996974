// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[domain-search-root] .domain-search-results-wrapper {width: 100%;max-width: 800px
}@media (min-width: 768px) {[domain-search-root] .domain-search-results-wrapper {padding-top: 1.25rem
    }
}[domain-search-root] .domain-search-results-wrapper .domain-search-partners-divider {display: flex;width: 100%;align-items: center;justify-content: space-between;gap: 0.75rem;padding-top: 120px;padding-bottom: 2.5rem
}@media (min-width: 768px) {[domain-search-root] .domain-search-results-wrapper .domain-search-partners-divider {padding-top: 160px
    }
}[domain-search-root] .domain-search-results-wrapper .domain-search-partners-divider::before {height: 1px;flex: 1 1 0%;content: var(--tw-content);--tw-bg-opacity: 1;background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1))
}[domain-search-root] .domain-search-results-wrapper .domain-search-partners-divider::after {height: 1px;flex: 1 1 0%;content: var(--tw-content);--tw-bg-opacity: 1;background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1))
}[domain-search-root] .domain-search-results-wrapper .domain-search-partners-divider p {text-align: center;font-family: "DM Sans", sans-serif;font-size: 0.875rem;line-height: 1.25rem;line-height: 17px;--tw-text-opacity: 1;color: rgb(96 121 137 / var(--tw-text-opacity, 1))
}@media (min-width: 768px) {[domain-search-root] .domain-search-results-wrapper .domain-search-partners-divider p {font-size: 1.25rem;line-height: 1.75rem;line-height: 1
    }
}[domain-search-root] .domain-search-results-wrapper .domain-search-results-partners-group {display: block
}[domain-search-root] .domain-search-results-wrapper .domain-search-results-partners-group .domain-search-registrar-card {border-radius: 0px;border-bottom-width: 0px
}[domain-search-root] .domain-search-results-wrapper .domain-search-results-partners-group .domain-search-registrar-card:first-child {border-top-right-radius: 1rem;border-top-left-radius: 1rem;border-bottom-right-radius: 0px;border-bottom-left-radius: 0px
}[domain-search-root] .domain-search-results-wrapper .domain-search-results-partners-group .domain-search-registrar-card:last-child {border-bottom-right-radius: 1rem;border-bottom-left-radius: 1rem;border-bottom-width: 1px
}`, "",{"version":3,"sources":["webpack://./src/components/domain-search-results/DomainSearchResultsDrawer.styles.scss"],"names":[],"mappings":"AACE,qDAAA,WAAA,CAAA;AAAA,CACA,2BAAA,qDAAA;IAAA;AAAA,CAGE,qFAAA,aAAA,CAAA,WAAA,CAAA,mBAAA,CAAA,8BAAA,CAAA,YAAA,CAAA,kBAAA,CAAA;AAAA,CACA,2BAAA,qFAAA;IAAA;AAAA,CACA,6FAAA,WAAA,CAAA,YAAA,CAAA,0BAAA,CAAA,kBAAA,CAAA;AAAA,CAAA,4FAAA,WAAA,CAAA,YAAA,CAAA,0BAAA,CAAA,kBAAA,CAAA;AAAA,CAGE,uFAAA,kBAAA,CAAA,kCAAA,CAAA,mBAAA,CAAA,oBAAA,CAAA,iBAAA,CAAA,oBAAA,CAAA;AAAA,CACA,2BAAA,uFAAA,kBAAA,CAAA,oBAAA,CAAA;IAAA;AAAA,CAKF,2FAAA;AAAA,CAGE,yHAAA,kBAAA,CAAA;AAAA,CAGE,qIAAA,6BAAA,CAAA,4BAAA,CACA,+BAAA,CAAA;AADA,CAKE,oIAAA,gCAAA,CAAA,+BAAA,CACA;AADA","sourcesContent":[".domain-search-results-wrapper {\n  @apply w-full max-w-[800px];\n  @apply md:pt-5;\n\n  & .domain-search-partners-divider {\n    @apply pt-[120px] pb-10 flex items-center justify-between gap-3 w-full;\n    @apply md:pt-[160px];\n    @apply before:flex-1 after:flex-1 before:bg-neutral-300 after:bg-neutral-300 before:h-[1px] after:h-[1px];\n\n    & p {\n      @apply font-primary text-sm leading-[17px] text-brand-secondary text-center;\n      @apply md:text-xl md:leading-none;\n    }\n  }\n\n  & .domain-search-results-partners-group {\n    @apply block;\n\n    & .domain-search-registrar-card {\n      @apply rounded-none border-b-0;\n\n      &:first-child {\n        @apply rounded-tr-2xl rounded-tl-2xl;\n        @apply rounded-br-none rounded-bl-none;\n      }\n\n        &:last-child {\n          @apply rounded-br-2xl rounded-bl-2xl;\n          @apply border-b;\n        }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
