import { FC } from 'react';

interface ClearInputButtonProps {
  callback: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ClearInputButton: FC<ClearInputButtonProps> = ({ callback }) => {
  return (
    <button type={'button'} className={'clear-input-button'} onMouseDown={callback}>
      <div className={'relative w-2 h-2 pointer-events-none'}>
        <svg
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={'clear-input-button-x'}
            d="M1 1L5 5M5 5L9 1M5 5L1 9M5 5L9 9"
            strokeWidth="1.45455"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </button>
  );
};
