// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[domain-search-root] .domain-search-premium-pill {
    --tw-bg-opacity: 1;
    background-color: rgb(221 242 226 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(55 163 74 / var(--tw-text-opacity, 1));
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1rem;
    display: inline-block;
    border-radius: 9999px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem
}`, "",{"version":3,"sources":["webpack://./src/components/domain-search-availability/components/DomainPremiumPill.styles.scss"],"names":[],"mappings":"AACE;IAAA,kBAAA;IAAA,4DAAA;IAAA,oBAAA;IAAA,iDAAA;IACA,kBAAA;IAAA,kCAAA;IAAA,kBAAA;IAAA,gBAAA;IAAA,yBAAA;IAAA,iBAAA;IACA,qBAAA;IAAA,qBAAA;IAAA,oBAAA;IAAA,uBAAA;IAAA,kBAAA;IAAA;AAFA","sourcesContent":[".domain-search-premium-pill {\n  @apply bg-success-light text-success;\n  @apply font-primary text-xs leading-4 font-bold uppercase text-center;\n  @apply inline-block py-1 px-4 rounded-full;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
