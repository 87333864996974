import { create } from 'zustand';
import { INTERCAP_REGISTRAR } from '@config/constants';
import { DomainPriceInfo } from 'types/DomainPriceInfo';
import { PriceInfo } from 'types/PriceInfo';

interface DomainSearchState {
  inputValue: string;
  isInputFocused: boolean;
  isSearching: boolean;
  isFirstSearchCompleted: boolean;
  invokeScroll: boolean;
  searchResult: DomainPriceInfo | null;
  intercapPriceInfo: PriceInfo | null;
  partnerPriceInfos: PriceInfo[];
  setInputValue: (value: string) => void;
  setIsInputFocused: (value: boolean) => void;
  setIsSearching: (value: boolean) => void;
  setIsFirstSearchCompleted: (value: boolean) => void;
  setInvokeScroll: (value: boolean) => void;
  setSearchResult: (value: DomainPriceInfo | null) => void;
}

export const useDomainSearchStateStore = create<DomainSearchState>(set => ({
  inputValue: '',
  isInputFocused: false,
  isSearching: false,
  isFirstSearchCompleted: false,
  invokeScroll: false,
  searchResult: null,
  intercapPriceInfo: null,
  partnerPriceInfos: [],
  setInputValue: value => set({ inputValue: value }),
  setIsInputFocused: value => set({ isInputFocused: value }),
  setIsSearching: value => set({ isSearching: value }),
  setIsFirstSearchCompleted: value => set({ isFirstSearchCompleted: value }),
  setInvokeScroll: value => set({ invokeScroll: value }),
  setSearchResult: value => {
    const intercapPriceInfo = value?.prices.find(priceInfo => priceInfo.registrar_name === INTERCAP_REGISTRAR) || null;
    const partnerPriceInfos = value?.prices.filter(priceInfo => priceInfo.registrar_name !== INTERCAP_REGISTRAR) || [];
    const nonErrorPartnerPriceInfos = partnerPriceInfos.filter(priceInfo => !priceInfo.error);
    set({ searchResult: value, intercapPriceInfo: intercapPriceInfo, partnerPriceInfos: nonErrorPartnerPriceInfos });
  },
}));
