import { FC } from 'react';

import './DomainSearchRegistrarCard.styles.scss';

export interface DomainSearchRegistrarCardProps {
  cardType?: 'partner' | 'intercap';
  ctaText?: string;
  externalLink: string;
  logoUrl: string;
  price: string;
  registrarName: string;
  hidePrice?: boolean;
  anchorTarget?: '_blank' | '_self';
  onClick?: () => void;
}

export const DomainSearchRegistrarCard: FC<DomainSearchRegistrarCardProps> = ({
  cardType = 'partner',
  ctaText = 'Buy Now',
  externalLink,
  logoUrl,
  price,
  registrarName,
  hidePrice = false,
  anchorTarget = '_blank',
  onClick,
}) => {
  return (
    <>
      {cardType === 'intercap' ? (
        <a
          data-domain-search-registrar-card={registrarName}
          className="domain-search-registrar-card domain-search-registrar-card--intercap"
          target={anchorTarget}
          href={externalLink}
          onClick={onClick}
        >
          <img className="domain-search-registrar-card__logo" src={logoUrl} alt={registrarName} loading={'eager'} />
          <div className="flex flex-col-reverse gap-4 md:flex-1 md:flex-row md:justify-between md:items-center">
            <div className="domain-search-registrar-card__special-offer">
              <div className="md:text-left md:max-w-[200px]">
                {anchorTarget === '_self' ? (
                  <>
                    <span>Domain + Email +</span> <span className="inline-block">Website included!</span>
                  </>
                ) : (
                  <>
                    <span>Use</span> <span className="font-bold text-brand-primary">INC100</span> <span>for</span>{' '}
                    <span className="inline-block">$100 OFF at Checkout</span>
                  </>
                )}
              </div>
            </div>
            <div className="domain-search-registrar-card__price">
              {!hidePrice ? (
                <span className="md:text-right">{price}</span>
              ) : (
                <span className="mobile-cta-text">{ctaText}</span>
              )}
            </div>
          </div>
          <div className="domain-search-registrar-card__cta">{ctaText}</div>
        </a>
      ) : (
        <a
          data-domain-search-registrar-card={registrarName}
          className="domain-search-registrar-card domain-search-registrar-card--partner"
          target={anchorTarget}
          href={externalLink}
          onClick={onClick}
        >
          <img className="domain-search-registrar-card__logo" src={logoUrl} alt={registrarName} loading={'eager'} />
          <div className="domain-search-registrar-card__price">{!hidePrice && <span>{price}</span>}</div>
          <div className="domain-search-registrar-card__cta">{ctaText}</div>
        </a>
      )}
    </>
  );
};
