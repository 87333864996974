import { config } from '@config/constants';
import { useDomainSearchStateStore } from '@stores/DomainSearchStateStore';
import { DomainPriceInfo } from 'types/DomainPriceInfo';

export const getDomainPriceInfo = async (
  domain: string,
  query_registrars: string[],
  sortBy?: string,
): Promise<void> => {
  try {
    const response = await fetch(
      `${config.registryAPIBaseUrl}/v1/domains/pricing?domain=${domain}&registrars=${query_registrars.join(
        ',',
      )}${sortBy ? `&sortBy=${sortBy}` : ''}&dynamic=1`,
    );
    const data = await response.json();
    const domainPriceInfo: DomainPriceInfo = data.data.domain_price_info;
    useDomainSearchStateStore.getState().setSearchResult(domainPriceInfo);
  } catch (_error) {
    console.error('Error fetching domain price info');
  }
};
