import { config } from '@config/constants';
import { getPageConfig } from '@fetch/getPageConfig';

export const startupValidation = (rootSelector: string) => {
  if (!config.registryAPIBaseUrl) {
    throw new Error('Registry API Base URL is not defined');
  }

  const pageConfig = getPageConfig(rootSelector);

  if (!pageConfig) {
    throw new Error('Page Config is not defined');
  }

  if (
    !pageConfig.query_registrars.length ||
    pageConfig.query_registrars[0] === '' ||
    pageConfig.query_registrars[0] === ' '
  ) {
    throw new Error('No query registrars were provided');
  }

  if (!pageConfig.registrar_infos.length) {
    throw new Error('No registrar infos were provided');
  }

  if (pageConfig.registrar_infos.some((info) => !info.registrar_name)) {
    throw new Error('Some registrar infos are missing registrar names');
  }

  if (pageConfig.registrar_infos.some((info) => !info.logo_url)) {
    throw new Error('Some registrar infos are missing logo URLs');
  }

  if (pageConfig.registrar_infos.some((info) => !info.redirect_url)) {
    throw new Error('Some registrar infos are missing redirect_url');
  }
};
