import { PageConfig } from 'types/PageConfig';

export const getPageConfig = (rootSelector: string): PageConfig | null => {
  try {
    return {
      is_redirect_component: document.querySelector(rootSelector)!.getAttribute('data-is-redirect') === '1',
      redirect_url: document.querySelector(rootSelector)!.getAttribute('data-redirect-url') || null,
      enable_intercap: document.querySelector(rootSelector)!.getAttribute('data-show-intercap') === '1',
      query_registrars: document.querySelector(rootSelector)!.getAttribute('data-query-registrars')!.split(','),
      sort_by: document.querySelector(rootSelector)!.getAttribute('data-sort-by') || undefined,
      registrar_infos,
    } as PageConfig;
  } catch (_error) {
    return null;
  }
};
