import { create } from 'zustand';
import { INTERCAP_REGISTRAR } from '@config/constants';
import { PageConfig, RegistrarInfo } from 'types/PageConfig';

interface PageConfigState {
  pageConfig: PageConfig | null;
  isRedirectComponent: boolean;
  redirectUrl: string | null;
  enableIntercap: boolean;
  sortBy: string | undefined;
  intercapRegistrarInfo: RegistrarInfo | null;
  partnerRegistrarInfos: RegistrarInfo[];
  setPageConfig: (value: PageConfig | null) => void;
}

export const usePageConfigStore = create<PageConfigState>(set => ({
  pageConfig: null,
  isRedirectComponent: false,
  redirectUrl: null,
  enableIntercap: false,
  sortBy: undefined,
  intercapRegistrarInfo: null,
  partnerRegistrarInfos: [],
  setPageConfig: (value: PageConfig | null) => {
    const isRedirectComponent = value?.is_redirect_component || false;
    const redirectUrl = value?.redirect_url || null;
    const enableIntercap = value?.enable_intercap || false;
    const sortBy = value?.sort_by || undefined;
    const intercap = value?.registrar_infos.find(r => r.registrar_name === INTERCAP_REGISTRAR) || null;
    const partners = value?.registrar_infos.filter(r => r.registrar_name !== INTERCAP_REGISTRAR) || [];
    set({
      pageConfig: value,
      isRedirectComponent: isRedirectComponent,
      redirectUrl: redirectUrl,
      intercapRegistrarInfo: intercap,
      partnerRegistrarInfos: partners,
      enableIntercap: enableIntercap,
      sortBy: sortBy,
    });
  },
}));
