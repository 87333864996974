import { FC, useEffect, useRef } from 'react';

interface LoadingSpinnerProps {
  className?: string;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className }) => {
  const spinnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const spinner = spinnerRef.current;
    let angle = 0;

    const rotate = () => {
      if (!spinner) return;
      angle = (angle + 12) % 360;
      spinner.style.transform = `rotate(${angle}deg)`;
      requestAnimationFrame(rotate);
    };

    rotate();

    return () => {
      angle = 0;
      if (spinner) {
        spinner.style.transform = '';
      }
    };
  }, []);

  return (
    <div className={`flex justify-center items-center ${className ? className : ''}`}>
      <div ref={spinnerRef} className="w-5 h-5 border-2 border-brand-primary rounded-full border-t-transparent"></div>
    </div>
  );
};
