import { FC } from 'react';
import { DomainPremiumPill } from './components/DomainPremiumPill';
import { usePreferredCharacters } from '../../util/usePreferredCharacters';

import './DomainSearchAvailabilityDisplay.styles.scss';

export interface DomainSearchAvailabilityDisplayProps {
  domain: string;
  isDomainAvailable: boolean;
  isDomainPremium: boolean;
}

export const DomainSearchAvailabilityDisplay: FC<DomainSearchAvailabilityDisplayProps> = ({
  domain,
  isDomainAvailable,
  isDomainPremium,
}) => {
  return (
    <div className={`domain-search-availability-status-display${isDomainAvailable ? ' pb-10' : ''}`}>
      {isDomainPremium && <DomainPremiumPill />}
      <p>
        <span className={'domain-search-heading-1'}>{usePreferredCharacters(domain)} </span>
        <span className={`domain-search-heading-1${isDomainAvailable ? ' !text-success' : ''}`}>
          {isDomainAvailable ? 'is available!' : 'is not available...'}
        </span>
      </p>
      {!isDomainAvailable && (
        <div className={'domain-search-try-again-text'}>Try searching for other domains below</div>
      )}
    </div>
  );
};
