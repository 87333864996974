// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[domain-search-root] .domain-search-availability-status-display {padding-top: 4rem;text-align: center
}[domain-search-root] .domain-search-availability-status-display__domain {font-weight: 700
}[domain-search-root] .domain-search-try-again-text {padding-top: 0.75rem;text-align: center;font-family: synthese, sans-serif;line-height: 1.25rem;--tw-text-opacity: 1;color: rgb(96 121 137 / var(--tw-text-opacity, 1))
}@media (min-width: 768px) {[domain-search-root] .domain-search-try-again-text {font-size: 1.25rem;line-height: 1.5rem
    }
}`, "",{"version":3,"sources":["webpack://./src/components/domain-search-availability/DomainSearchAvailabilityDisplay.styles.scss"],"names":[],"mappings":"AACE,iEAAA,iBAAA,CAAA;AAAA,CAGE,yEAAA;AAAA,CAKF,oDAAA,oBAAA,CAAA,kBAAA,CACA,iCAAA,CAAA,oBAAA,CAAA,oBAAA,CAAA;AADA,CAEA,2BAAA,oDAAA,kBAAA,CAAA;IAAA;AAAA","sourcesContent":[".domain-search-availability-status-display {\n  @apply pt-16 text-center;\n\n  &__domain {\n    @apply font-bold;\n  }\n}\n\n.domain-search-try-again-text {\n  @apply pt-3 text-center;\n  @apply font-secondary leading-5 text-brand-secondary;\n  @apply md:text-xl md:leading-6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
