import { FC, useEffect, useRef } from 'react';
import { SearchIcon } from './components/SearchIcon';
import { useDomainSearchStateStore } from '@stores/DomainSearchStateStore';
import { useShallow } from 'zustand/react/shallow';
import { useWindowSize } from '@hooks/useWindowSize';
import { LoadingSpinner } from './components/LoadingSpinner';
import { ClearInputButton } from './components/ClearInputButton';

import './DomainSearchForm.styles.scss';

export interface DomainSearchFormProps {
  inputValue: string;
  isErrorState: boolean;
  isSearchDisabled: boolean;
  isSearchAgainType?: boolean;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const DomainSearchForm: FC<DomainSearchFormProps> = ({
  inputValue,
  submitHandler,
  onChangeHandler,
  isErrorState,
  isSearchDisabled,
  isSearchAgainType = false,
}) => {
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { searchResult, isSearching } = useDomainSearchStateStore(
    useShallow(state => ({ searchResult: state.searchResult, isSearching: state.isSearching })),
  );

  const { setInputValue } = useDomainSearchStateStore();

  const { width } = useWindowSize();

  useEffect(() => {
    if (searchResult && inputRef.current) {
      if (width && width < 768) {
        inputRef.current.blur();
        return;
      }
    }
  }, [searchResult, width]);

  return (
    <div
      ref={formWrapperRef}
      className={`domain-search-form-wrapper${isSearchAgainType ? ' domain-search-form-wrapper--search-again' : ''}`}
    >
      <form data-domain-search-form={'1'} className={'domain-search-form'} method={'dialog'} onSubmit={submitHandler}>
        <div className={'input-icon-wrapper'}>
          <input
            ref={inputRef}
            data-domain-search-query-input={'1'}
            title={'Please enter a domain name'}
            className={`domain-search-query-input${isErrorState ? ' !border-[#f00] !text-[#f00]' : ''}`}
            type={'search'}
            name={'domain-query'}
            placeholder={`${!isSearchAgainType ? 'Type the domain you want' : 'Search again'}`}
            required
            onChange={onChangeHandler}
            onInput={onChangeHandler}
            value={inputValue}
          />
          {isSearching && <LoadingSpinner className={'domain-search-loading-spinner'} />}
          {!isSearching && inputValue && <ClearInputButton callback={() => setInputValue('')} />}
        </div>
        <button type={'submit'} disabled={isSearchDisabled}>
          <span className={'domain-search-form-submit__text'}>
            {!isSearchAgainType ? 'Get Domain' : 'Search Again'}
          </span>
          <span className={'domain-search-form-submit__icon'}>
            <SearchIcon />
          </span>
        </button>
      </form>
    </div>
  );
};
